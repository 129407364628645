import type { NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Router from 'next/router';
import { useEffect } from 'react';
import { PrivateRoute } from '../utils/auth';

const Home: NextPage = () => {
  useEffect(() => {
    Router.push('/events');
  }, []);

  return <></>;
};

export default PrivateRoute(Home);

export async function getServerSideProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}
